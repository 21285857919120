/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/bootstrap.min.css'
import './src/styles/flexboxgrid-helpers.min.css'
import './src/styles/font-awesome.min.css'
import './src/styles/owl.carousel.css'
import './src/styles/owl.theme.default.css'
import './src/styles/layout.css'
import './src/styles/style.css'

export const onClientEntry = () => {}

export const onInitialClientRender = () => {
  console.log('ReactDOM.render has executed')

  if (window.GPBootstrapJQueryPlugins) {
    window.GPBootstrapJQueryPlugins()
  }
  
  if (window.setupLangSwitcher) {
    window.setupLangSwitcher()
  }
}
